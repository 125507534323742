<template>
  <v-card
    v-if="$route.query.id"
    :key="mounted"
    :class="highlightForm && 'highlight-required-fields'"
  >
    <div class="mb-4 sticky-header">
      <PatientInformation
        v-if="encounter.practice_type_id && patient"
        title="Psychology Encounter Detail"
        :patient="patient"
        :visitType="encounter.visit_type"
      ></PatientInformation>
    </div>

    <Attachments
      :is-signed="encounter.is_signed"
      :patient-id="patient.id"
      :encounter-attachments.sync="patient.all_attachments"
    ></Attachments>

    <v-card-actions class="align-start pb-0">
      <!-- validated -->
      <icon-value
        :icon="formValidated ? icons.mdiCheckDecagram : icons.mdiCloseCircle"
        :value="formValidated ? 'Validated' : 'Not Validated'"
        :color="formValidated ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- signed -->
      <icon-value
        :icon="encounter.is_signed ? icons.mdiClipboardCheck : icons.mdiCloseCircle"
        :value="encounter.is_signed ? 'Signed' : 'Not Signed'"
        :color="encounter.is_signed ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- synced -->
      <icon-value
        :icon="encounter.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle"
        :value="encounter.is_synced ? 'Synced' : 'Not Synced'"
        :color="encounter.is_synced ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- review surgical note -->
      <btn
        v-if="formValidated"
        label="Review Note"
        :icon="icons.mdiEyeOutline"
        @click="viewSurgicalNote"
      ></btn>

      <!-- validate note -->
      <btn
        v-else
        label="Validate"
        color="warning"
        :icon="icons.mdiCheckDecagram"
        @click="validateEncounter"
      ></btn>
    </v-card-actions>

    <!-- *** INSURANCE AND AUTHORIZATION -->
    <NoteSection
      label="Insurance &amp; Authorization"
      heading
    >
    </NoteSection>
    <InsuranceAuthorization
      :encounter="encounter"
      :patient-id="patient.id"
      :comment-adds="true"
    >
    </InsuranceAuthorization>

    <NoteSection
      label="Encounter Comments"
      heading
    ></NoteSection>
    <Comments
      type="encounter"
      :encounter-id="encounter.id"
      :patient-id="patient.id"
    >
    </Comments>

    <v-form
      v-model="formValid"
      :class="encounter.is_signed ? 'form-disabled' : ''"
      :disabled="encounter.is_signed"
    >
      <EncounterDetails
        :visit-location.sync="encounter.visit_location"
        :visit-type="encounter.visit_type"
        :place-of-service-id.sync="encounter.place_of_service_id"
        :visit-date.sync="encounter.visit_date"
        :encounter="encounter"
      ></EncounterDetails>

      <!-- *** REFERRAL SOURCE *** -->
      <NoteSection
        label="Referral Source"
        heading
      ></NoteSection>

      <NoteSection label="Referral Source Statement of Need">
        <text-area
          v-model="encounter.psychology.referral_source_statement_of_need"
          label="Enter referral source statement of need..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** HISTORY *** -->
      <NoteSection
        label="History"
        heading
      ></NoteSection>

      <NoteSection label="Past Medical History">
        <text-area
          v-model="encounter.psychology.past_medical_history"
          label="Enter past medical history..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Past Psychiatry History">
        <text-area
          v-model="encounter.psychology.past_psychiatry_history"
          label="Enter past psychiatry history..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Psychiatric Treatment History">
        <text-area
          v-model="encounter.psychology.psychiatric_treatment_history"
          label="Enter psychiatric treatment history..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** MEDICATIONS *** -->
      <NoteSection
        label="Medications"
        heading
      ></NoteSection>

      <NoteSection label="Relevant Non-Psych Medications">
        <text-area
          v-model="encounter.psychology.relevant_non_psych_medications"
          label="Enter relevant non-psych medications..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Current Psych Medications">
        <text-area
          v-model="encounter.psychology.current_psych_medications"
          label="Enter current psych medications..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Medications, Allergies/Adverse Reactions">
        <radio-buttons
          v-model="encounter.psychology.adverse_reactions"
          :items="query"
          label="Medications, allergies/adverse reactions?"
          class="col-sm-12"
          required
          row
        ></radio-buttons>
        <text-area
          v-if="encounter.psychology.adverse_reactions"
          v-model="encounter.psychology.adverse_reactions_yes"
          label="Enter medications, allergies/adverse reactions..."
          class="col-sm-12"
          required
        ></text-area>

        <radio-buttons
          v-model="encounter.psychology.psychotropic_medication_reactions"
          :items="query"
          label="Known allergies/adverse reactions to psychotropic medications?"
          class="col-sm-12"
          required
          row
        ></radio-buttons>
        <text-area
          v-if="encounter.psychology.psychotropic_medication_reactions"
          v-model="encounter.psychology.psychotropic_medication_reactions_yes"
          label="Enter known allergies/adverse reactions to psychotropic medications..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** SUBSTANCE ABUSE *** -->
      <NoteSection
        label="Substance Abuse History"
        heading
      ></NoteSection>

      <NoteSection>
        <text-area
          v-model="encounter.psychology.substance_abuse_history"
          label="Enter substance abuse history..."
          class="col-sm-12"
          required
        ></text-area>
        <radio-buttons
          v-model="encounter.psychology.substance_abuse_treatment_history"
          :items="query"
          label="Substance abuse treatment history?"
          class="col-sm-12"
          required
          row
        ></radio-buttons>
        <text-area
          v-if="encounter.psychology.substance_abuse_treatment_history"
          v-model="encounter.psychology.substance_abuse_treatment_history_yes"
          label="Enter substance abuse treatment history..."
          class="col-sm-12"
          required
        ></text-area>
        <radio-buttons
          v-model="encounter.psychology.recent_alcohol_and_drug_use"
          :items="query"
          label="Recent (30 days) alcohol and drug use?"
          class="col-sm-12"
          required
          row
        ></radio-buttons>
        <text-area
          v-if="encounter.psychology.recent_alcohol_and_drug_use"
          v-model="encounter.psychology.recent_alcohol_and_drug_use_yes"
          label="Enter history of use, duration, patterns & consequences..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** FAMILY AND SOCIAL STATUS *** -->
      <NoteSection
        label="Family and Social Status"
        heading
      ></NoteSection>

      <NoteSection label="Family Status">
        <text-area
          v-model="encounter.psychology.family_status"
          label="Enter current and historical family status..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Social Status">
        <text-area
          v-model="encounter.psychology.social_status"
          label="Enter current and historical social status..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** HISTORY OF PRESENT ILLNESS *** -->
      <NoteSection
        label="History of Present Illness"
        heading
      ></NoteSection>
      <NoteSection>
        <text-area
          v-model="encounter.psychology.history_of_present_illness"
          label="Enter history of present illness..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** GENERAL ASSESSMENT/MENTAL STATUS *** -->
      <NoteSection
        label="General Assessment/Mental Status"
        heading
      ></NoteSection>
      <NoteSection label="Appearance">
        <radio-buttons
          v-model="encounter.psychology.appearance"
          :items="appearances"
          class="col-sm-12 mt--3"
          columns="4"
          hide-details
          required
        ></radio-buttons>
      </NoteSection>

      <NoteSection label="Behavior">
        <check-boxes
          v-model="encounter.psychology.behaviors"
          :items="behaviors"
          class="col-sm-12 mt--3"
          columns="4"
          hide-details
          required
          return-object
        ></check-boxes>
      </NoteSection>

      <NoteSection label="Speech">
        <check-boxes
          v-model="encounter.psychology.speeches"
          :items="speeches"
          class="col-sm-12 mt--3"
          columns="4"
          hide-details
          required
          return-object
        ></check-boxes>
      </NoteSection>

      <NoteSection label="Orientation">
        <radio-buttons
          v-model="encounter.psychology.orientation"
          :items="orientations"
          class="col-sm-12 mt--3"
          columns="5"
          hide-details
          required
        ></radio-buttons>
      </NoteSection>

      <NoteSection>
        <radio-buttons
          v-model="encounter.psychology.memory"
          :items="memories"
          label="Memory"
          class="col-sm-3"
          label-title
          column
          required
        ></radio-buttons>
        <radio-buttons
          v-model="encounter.psychology.intellect"
          :items="intellects"
          label="Intellect"
          class="col-sm-3"
          label-title
          column
          required
        ></radio-buttons>
        <radio-buttons
          v-model="encounter.psychology.concentration"
          :items="concentrations"
          label="Concentration"
          class="col-sm-3"
          label-title
          column
          required
        ></radio-buttons>
        <check-boxes
          v-model="encounter.psychology.thought_flows"
          :items="thoughtFlows"
          label="Thought Flow"
          class="col-sm-3"
          label-title
          column
          required
          return-object
        ></check-boxes>
      </NoteSection>

      <NoteSection label="Insight">
        <radio-buttons
          v-model="encounter.psychology.insight"
          :items="insights"
          class="col-sm-12 mt--3"
          columns="4"
          hide-details
          required
        ></radio-buttons>
      </NoteSection>

      <NoteSection label="Mood">
        <check-boxes
          v-model="encounter.psychology.moods"
          :items="moods"
          class="col-sm-12 mt--3"
          columns="4"
          hide-details
          required
          return-object
        ></check-boxes>
      </NoteSection>

      <NoteSection label="Affect">
        <check-boxes
          v-model="encounter.psychology.affects"
          :items="affects"
          class="col-sm-12 mt--3"
          columns="4"
          hide-details
          required
          return-object
        ></check-boxes>
      </NoteSection>

      <NoteSection>
        <radio-buttons
          v-model="encounter.psychology.hallucinations"
          :items="query"
          label="Hallucinations"
          class="col-sm-6"
          label-title
          hide-details
          columns="4"
          required
        ></radio-buttons>
        <radio-buttons
          v-model="encounter.psychology.delusions"
          :items="query"
          label="Delusions"
          class="col-sm-6"
          label-title
          hide-details
          columns="4"
          required
        ></radio-buttons>
        <text-area
          v-if="encounter.psychology.hallucinations"
          v-model="encounter.psychology.hallucinations_yes"
          label="Enter hallucinations explanation..."
          class="col-sm-6"
          required
        ></text-area>
        <v-spacer
          v-else
          class="col-sm-6"
        ></v-spacer>
        <text-area
          v-if="encounter.psychology.delusions"
          v-model="encounter.psychology.delusions_yes"
          label="Enter delusions explanation..."
          class="col-sm-6"
          required
        ></text-area>
      </NoteSection>

      <NoteSection>
        <radio-buttons
          v-model="encounter.psychology.homicidal"
          :items="query"
          label="Homicidal"
          class="col-sm-6"
          label-title
          hide-details
          columns="4"
          required
        ></radio-buttons>
        <radio-buttons
          v-model="encounter.psychology.suicidal"
          :items="query"
          label="Suicidal"
          class="col-sm-6"
          label-title
          hide-details
          columns="4"
          required
        ></radio-buttons>
        <text-area
          v-if="encounter.psychology.homicidal"
          v-model="encounter.psychology.homicidal_yes"
          label="Enter homicidal explanation..."
          class="col-sm-6"
          required
        ></text-area>
        <v-spacer
          v-else
          class="col-sm-6"
        ></v-spacer>
        <text-area
          v-if="encounter.psychology.suicidal"
          v-model="encounter.psychology.suicidal_yes"
          label="Enter suicidal explanation..."
          class="col-sm-6"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Judgement">
        <radio-buttons
          v-model="encounter.psychology.judgement"
          :items="judgements"
          class="col-sm-12 mt--3"
          columns="4"
          hide-details
          required
        ></radio-buttons>
      </NoteSection>

      <!-- *** DIAGNOSIS *** -->
      <NoteSection
        label="Diagnosis"
        heading
      ></NoteSection>

      <NoteSection>
        <select-box-other-input
          v-model="encounter.psychology.diagnosis"
          :items="psychologyDiagnoses"
          label="Select diagnosis..."
          class="col-sm-12"
          other-label="Enter diagnosis..."
          other-text-area
          other-required
        ></select-box-other-input>
      </NoteSection>

      <NoteSection label="Substantiated/Previous Diagnosis">
        <text-area
          v-model="encounter.psychology.previous_diagnosis"
          label="Enter substantiated/previous diagnosis..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Diagnostic Impressions">
        <text-area
          v-model="encounter.psychology.diagnostic_impression"
          label="Enter diagnostic impressions..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** STRENGTHS & LIMITATIONS *** -->
      <NoteSection
        label="Strengths & Limitations"
        heading
      ></NoteSection>

      <NoteSection label="Strengths">
        <check-boxes
          v-model="encounter.psychology.strengths"
          :items="strengths"
          class="col-sm-12"
          columns="3"
          column-sort
          required
          return-object
        ></check-boxes>
        <text-area
          v-if="encounter.psychology.strengths.some(x => x.title === 'Other')"
          v-model="encounter.psychology.strengths_other"
          label="Enter other strengths..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Limitations">
        <check-boxes
          v-model="encounter.psychology.limitations"
          :items="limitations"
          class="col-sm-12"
          columns="3"
          column-sort
          required
          return-object
          distinct="None"
        ></check-boxes>
        <text-area
          v-if="encounter.psychology.limitations.some(x => x.title === 'Other')"
          v-model="encounter.psychology.limitations_other"
          label="Enter other limitations..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Impaired Functions">
        <check-boxes
          v-model="encounter.psychology.impaired_functions"
          :items="impairedFunctions"
          class="col-sm-12"
          columns="3"
          column-sort
          required
          return-object
        ></check-boxes>
        <text-area
          v-if="encounter.psychology.impaired_functions.some(x => x.title === 'Other')"
          v-model="encounter.psychology.impaired_functions_other"
          label="Enter other impaired functions..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** FOLLOWUP PLAN *** -->
      <NoteSection
        label="Follow Up Plan"
        heading
      ></NoteSection>

      <NoteSection label="Plan/Recommendations">
        <text-area
          v-model="encounter.psychology.recommendations"
          label="Enter plan/recommendations..."
          class="col-sm-12"
          rows="5"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Frequency of Follow Up Visits">
        <text-area
          v-model="encounter.psychology.frequency_of_follow_up_visits"
          label="Enter frequency of follow up visits..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <NoteSection label="Estimated Duration">
        <text-area
          v-model="encounter.psychology.estimated_duration"
          label="Enter estimated duration..."
          class="col-sm-12"
          rows="2"
          required
        ></text-area>
      </NoteSection>

      <!-- *** PROGNOSIS *** -->
      <NoteSection
        label="Prognosis"
        heading
      ></NoteSection>

      <NoteSection label="Prognosis">
        <text-area
          v-model="encounter.psychology.prognosis"
          label="Enter prognosis..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <!-- *** TREATMENT MODIFICATIONS *** -->
      <NoteSection
        label="Treatment Modifications for Cognitive Impairment"
        heading
      ></NoteSection>

      <NoteSection>
        <radio-buttons
          v-model="encounter.psychology.treatment_modification_required_for"
          :items="query"
          label="Are treatment modifications required for cognitive impairment?"
          class="col-sm-12 mt-3"
          required
          row
        ></radio-buttons>
        <text-area
          v-if="encounter.psychology.treatment_modification_required_for"
          v-model="encounter.psychology.treatment_modification_required_for_yes"
          label="Enter explanation for treatment modifications required for cognitive impairment..."
          class="col-sm-12"
          required
        ></text-area>
      </NoteSection>

      <v-card-actions class="sticky-bottom">
        <!-- cancel -->
        <btn
          v-if="!encounter.is_signed && encounterChanged"
          label="Cancel"
          color="secondary"
          :icon="icons.mdiCancel"
          @click="cancelEncounter"
        ></btn>

        <!-- close -->
        <btn
          v-else
          label="Close"
          color="secondary"
          :icon="icons.mdiClose"
          @click="exit()"
        ></btn>

        <!-- delete note -->
        <btn
          v-if="!encounter.is_signed"
          label="Delete"
          :icon="icons.mdiTrashCan"
          :disabled="encounter.is_signed"
          color="error"
          @click="deleteEncounter"
        ></btn>

        <v-spacer></v-spacer>

        <!-- save note -->
        <btn
          v-if="formValidated"
          label="Save Note"
          :icon="icons.mdiContentSave"
          color="success"
          :disabled="invalidVisitDate"
          @click="saveEncounter"
        ></btn>

        <!-- save draft -->
        <btn
          v-else
          label="Save Draft"
          :icon="icons.mdiContentSaveEdit"
          color="success"
          :disabled="invalidVisitDate"
          @click="saveDraft"
        ></btn>

        <!-- review surgical note -->
        <btn
          v-if="formValidated"
          label="Review Note"
          :icon="icons.mdiEyeOutline"
          @click="viewSurgicalNote"
        ></btn>

        <!-- validate note -->
        <btn
          v-else
          label="Validate"
          color="warning"
          :icon="icons.mdiCheckDecagram"
          :disabled="invalidVisitDate"
          @click="validateEncounter"
        ></btn>
      </v-card-actions>
    </v-form>
    <!-- dump object -->
    <div
      v-if="$authUser.user() && $authUser.user().is_superuser"
      class="btn-top-center"
    >
      <btn
        label="Dump"
        :icon="icons.mdiDownload"
        x-small
        color="secondary"
        @click="$store.commit('encounters/updateEncounter', { encounter, patient });
                $router.push({ name: 'dump-encounter', query: { id: encounter.id } })"
      ></btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

// Mixins
import Psychology from '@/mixins/practice-types/Psychology'
import Encounter from '@/mixins/Encounter'

// Insurance & Authorization
import InsuranceAuthorization from '@/components/notes/insurance/InsuranceAuthorization.vue'

import EncounterDetail from '@/mixins/EncounterDetail'
import Comments from '@/components/features/Comments.vue'

import PatientInformation from '@/components/notes/encounter/PatientInformation.vue'
import EncounterDetails from '@/components/notes/encounter/EncounterDetails.vue'

export default {
  components: {
    PatientInformation, EncounterDetails, InsuranceAuthorization, Comments,
  },
  mixins: [Psychology, Encounter, EncounterDetail],
  data() {
    return {
      isQuickEntry: false,
      diagnosis: null,
    }
  },
  computed: {
    ...mapGetters('baseData', [
      'query',
      'psychologyDiagnoses',
    ]),
    ...mapGetters('encounters', [
      'appearances',
      'orientations',
      'memories',
      'intellects',
      'concentrations',
      'insights',
      'behaviors',
      'speeches',
      'thoughtFlows',
      'moods',
      'affects',
      'limitations',
      'strengths',
      'impairedFunctions',
      'judgements',
    ]),
    diagnosisOnMenu() {
      return this.psychologyDiagnoses.some(x => x.title === this.diagnosis)
    },
    diagnosisIsEmpty() {
      return this.$custom.isEmpty(this.diagnosis)
    },
    diagnosisOther() {
      return this.diagnosis === 'Other' || (!this.diagnosisOnMenu && !this.diagnosisIsEmpty)
    },
  },
  watch: {
  },
  mounted() {
    this.diagnosis = this.encounter.psychology.diagnosis
    if (this.diagnosisOther) this.diagnosis = 'Other'
  },
  methods: {
    diagnosesSelectChanged() {
      if (this.diagnosisIsEmpty || this.diagnosisOther) {
        this.encounter.psychology.diagnosis = null
      } else if (this.diagnosisOnMenu) {
        this.encounter.psychology.diagnosis = this.diagnosis
      }
    },
    deleteEncounter() {
      this.$root.confirm({
        title: 'Delete Encounter Note?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to delete this encounter note?',
        confirm: 'Delete Note',
        confirmIcon: this.icons.mdiTrashCan,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          if (this.encounter.can_be_deleted) {
            this.$store.commit('encounters/deleteEncounter', this.encounter)
          } else {
            this.encounter.deleted = true
            this.encounter.is_synced = false
            this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
          }
          this.$store.dispatch('notify', { value: 'Encounter note deleted successfully.' })
          this.encounterChanged = false
          this.exit()
        }
      })
    },
  },
}
</script>
